import dynamic from "next/dynamic";

const Hero = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-hero"),
  {
    ssr: true,
  }
);
const Home = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-home"),
  {
    ssr: true,
  }
);
const Cards = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-cards"),
  {
    ssr: true,
  }
);
const CardsSlider = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-cards-slider"),
  {
    ssr: true,
  }
);
const Highlights = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-highlights"),
  {
    ssr: true,
  }
);
const HistoryCard = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-history-card"),
  {
    ssr: true,
  }
);
const HistoriaMenu = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-history-menu"),
  {
    ssr: true,
  }
);
const HomeBodega = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-home-bodega"),
  {
    ssr: true,
  }
);
const CarrouselHome = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-carrousel-home"),
  {
    ssr: true,
  }
);
const CarrouselFull = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-carrousel-full"),
  {
    ssr: true,
  }
);
const CarrouselHalf = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-carrousel-half"),
  {
    ssr: true,
  }
);
const TextPhoto = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-text-photo"),
  {
    ssr: true,
  }
);
const Services = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-services"),
  {
    ssr: true,
  }
);
const SelectEbox = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-select-e-box"),
  {
    ssr: true,
  }
);
const Reservar = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-reservar"),
  {
    ssr: true,
  }
);
const Details = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-detalles"),
  {
    ssr: true,
  }
);
const Carta = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-ficha-carta"),
  {
    ssr: true,
  }
);
const Faq = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-faq"),
  {
    ssr: true,
  }
);
const Chef = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-chef"),
  {
    ssr: true,
  }
);
const CTA = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-cta"),
  {
    ssr: true,
  }
);
const Double = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-double"),
  {
    ssr: true,
  }
);
const ExperienciaDetail = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-experiencia-detail"),
  {
    ssr: true,
  }
);
const Contact = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-contact"),
  {
    ssr: true,
  }
);
const BlogHeader = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-blog-header"),
  {
    ssr: true,
  }
);
const BlogPost = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-blog-post"),
  {
    ssr: true,
  }
);
const DescubraDouble = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-descubra-double"),
  {
    ssr: true,
  }
);
const Mantra = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-mantra"),
  {
    ssr: true,
  }
);
const Historia = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-historia"),
  {
    ssr: true,
  }
);
const Dependencias = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-dependencias"),
  {
    ssr: true,
  }
);
const Expertos = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-expertos"),
  {
    ssr: true,
  }
);
const Informes = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-informes"),
  {
    ssr: true,
  }
);
const Confiado = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-confiado"),
  {
    ssr: true,
  }
);
const Testimonios = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-testimonios"),
  {
    ssr: true,
  }
);
const Metodologia = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-metodologia"),
  {
    ssr: true,
  }
);
const Terruno = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-terruno"),
  {
    ssr: true,
  }
);
const ProductDetail = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-product-detail"),
  {
    ssr: true,
  }
);
const FormExperiencias = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-form-experiencias"),
  {
    ssr: true,
  }
);
const FormEventos = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-form-eventos"),
  {
    ssr: true,
  }
);
const FormSpa = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-form-spa"),
  {
    ssr: true,
  }
);
const FormEnologo = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-form-enologo"),
  {
    ssr: true,
  }
);
const DetallesDos = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-detalles-dos"),
  {
    ssr: true,
  }
);
const NoticiasCross = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-noticias-cross"),
  {
    ssr: true,
  }
);
const Cart = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-cart"),
  {
    ssr: true,
  }
);
const NovartisNavidad = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("ab-novartis-navidad"),
  {
    ssr: true,
  }
);
const Disfrute = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("./DisfruteDeAbadiaRetuerta"),
  {
    ssr: true,
  }
);
const Wellness = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("./DisfruteDelWellness"),
  {
    ssr: true,
  }
);
const Experiencias = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("./ExperienciasUnicas"),
  {
    ssr: true,
  }
);
const Gastronomia = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("./GastronomiaDeAbadia"),
  {
    ssr: true,
  }
);
const RegaleExperiencias = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("./RegaleUnaExperiencia"),
  {
    ssr: true,
  }
);
const Hotel = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("./UnHotelAMedida"),
  {
    ssr: true,
  }
);
const ProductCategory = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("./ProductCategory"),
  {
    ssr: true,
  }
);
const ProductRows = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("./ProductRows"),
  {
    ssr: true,
  }
);
const NuestraSugerencias = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import("./NuestrasSugerencias"),
  {
    ssr: true,
  }
);

const DynamicContent = ({ fieldGroupName, ...rest }) => {
  let Block = () => <div>{`block ${fieldGroupName} missing`}</div>;
  if (typeof window !== "undefined") {
    // console.log("DynamicContent", fieldGroupName, rest);
  }
  // console.log("DynamicContent", fieldGroupName, rest);
  switch (fieldGroupName) {
    case "Page_Contenidoflexible_Modulos_Hero":
      Block = Hero;
      break;
    case "Page_Contenidoflexible_Modulos_Home":
      Block = Home;
      break;
    case "Page_Contenidoflexible_Modulos_Cards":
      Block = Cards;
      break;
    case "Page_Contenidoflexible_Modulos_Cardsslider":
      Block = CardsSlider;
      break;
    case "Page_Contenidoflexible_Modulos_Highlight":
      Block = Highlights;
      break;
    case "Page_Contenidoflexible_Modulos_Historiacard":
      Block = HistoryCard;
      break;
    case "Page_Contenidoflexible_Modulos_Historiamenu":
      Block = HistoriaMenu;
      break;
    case "Page_Contenidoflexible_Modulos_Homebodega":
      Block = HomeBodega;
      break;
    case "Page_Contenidoflexible_Modulos_Carrouselhome":
      Block = CarrouselHome;
      break;
    case "Page_Contenidoflexible_Modulos_Carrouselfull":
      Block = CarrouselFull;
      break;
    case "Page_Contenidoflexible_Modulos_Carrouselhalf":
      Block = CarrouselHalf;
      break;
    case "Page_Contenidoflexible_Modulos_Textphoto":
      Block = TextPhoto;
      break;
    case "Page_Contenidoflexible_Modulos_Services":
      Block = Services;
      break;
    case "Page_Contenidoflexible_Modulos_Selectebox":
      Block = SelectEbox;
      break;
    case "Page_Contenidoflexible_Modulos_Reservar":
      Block = Reservar;
      break;
    case "Page_Contenidoflexible_Modulos_Detalles":
      Block = Details;
      break;
    case "Page_Contenidoflexible_Modulos_Carta":
      Block = Carta;
      break;
    case "Page_Contenidoflexible_Modulos_Faq":
      Block = Faq;
      break;
    case "Page_Contenidoflexible_Modulos_Chef":
      Block = Chef;
      break;
    case "Page_Contenidoflexible_Modulos_Cta":
      Block = CTA;
      break;
    case "Page_Contenidoflexible_Modulos_Disfrute":
      Block = Disfrute;
      break;
    case "Page_Contenidoflexible_Modulos_Disfrute":
      Block = Disfrute;
      break;
    case "Page_Contenidoflexible_Modulos_CrossDisfruteDelWellness":
      Block = Wellness;
      break;
    case "Post_Contenidoflexible_Modulos_CrossDisfruteDelWellness":
      Block = Wellness;
      break;
    case "Page_Contenidoflexible_Modulos_CrossExperienciasUnicas":
      Block = Experiencias;
      break;
    case "Post_Contenidoflexible_Modulos_CrossExperienciasUnicas":
      Block = Experiencias;
      break;
    case "Page_Contenidoflexible_Modulos_CrossGastronomiaDeAbadia":
      Block = Gastronomia;
      break;
    case "Post_Contenidoflexible_Modulos_CrossGastronomiaDeAbadia":
      Block = Gastronomia;
      break;
    case "Page_Contenidoflexible_Modulos_CrossRegaleUnaExperiencia":
      Block = RegaleExperiencias;
      break;
    case "Post_Contenidoflexible_Modulos_CrossRegaleUnaExperiencia":
      Block = RegaleExperiencias;
      break;
    case "Page_Contenidoflexible_Modulos_CrossUnHotelASuMedida":
      Block = Hotel;
      break;
    case "Post_Contenidoflexible_Modulos_CrossUnHotelASuMedida":
      Block = Hotel;
      break;
    case "Page_Contenidoflexible_Modulos_Double":
      Block = Double;
      break;
    case "Page_Contenidoflexible_Modulos_ExperienciaDetail":
      Block = ExperienciaDetail;
      break;
    case "Page_Contenidoflexible_Modulos_Contacta":
      Block = Contact;
      break;
    case "Page_Contenidoflexible_Modulos_BlogHeader":
      Block = BlogHeader;
      break;
    case "Post_Contenidoflexible_Modulos_BlogPost":
      Block = BlogPost;
      break;
    case "Page_Contenidoflexible_Modulos_BlogPost":
      Block = BlogPost;
      break;
    case "Page_Contenidoflexible_Modulos_DescubraDouble":
      Block = DescubraDouble;
      break;
    case "Page_Contenidoflexible_Modulos_Mantra":
      Block = Mantra;
      break;
    case "Page_Contenidoflexible_Modulos_Historia":
      Block = Historia;
      break;
    case " Page_Contenidoflexible_Modulos_Dependencias":
      Block = Dependencias;
      break;
    case "Page_Contenidoflexible_Modulos_Expertos":
      Block = Expertos;
      break;
    case "Page_Contenidoflexible_Modulos_Informes":
      Block = Informes;
      break;
    case "Page_Contenidoflexible_Modulos_Terruno":
      Block = Terruno;
      break;
    case "Page_Contenidoflexible_Modulos_Metodologia":
      Block = Metodologia;
      break;
    case "Page_Contenidoflexible_Modulos_HanConfiado":
      Block = Confiado;
      break;
    case "Page_Contenidoflexible_Modulos_Testimonios":
      Block = Testimonios;
      break;
    case "Page_Contenidoflexible_Modulos_ProductCategory":
      Block = ProductCategory;
      break;
    case "Page_Contenidoflexible_Modulos_ProductRows":
      Block = ProductRows;
      break;
    case "Page_Contenidoflexible_Modulos_ProductDetail":
      Block = ProductDetail;
      break;
    case "Page_Contenidoflexible_Modulos_CrossNuestrasSugerencias":
      Block = NuestraSugerencias;
      break;
    case "Page_Contenidoflexible_Modulos_FormularioExperiencias":
      Block = FormExperiencias;
      break;
    case "Page_Contenidoflexible_Modulos_FormularioEventos":
      Block = FormEventos;
      break;
    case "Page_Contenidoflexible_Modulos_FormularioSpa":
      Block = FormSpa;
      break;
    case "Page_Contenidoflexible_Modulos_FormularioEnologo":
      Block = FormEnologo;
      break;
    case "Page_Contenidoflexible_Modulos_EscapadaDetallesDos":
      Block = DetallesDos;
      break;
    case "Page_Contenidoflexible_Modulos_CrossNoticias":
      Block = NoticiasCross;
      break;
    case "Page_Contenidoflexible_Modulos_Carrito":
      Block = Cart;
      break;
    case "Page_Contenidoflexible_Modulos_NovartisNavidad":
      Block = NovartisNavidad;
      break;
  }

  return Block ? <Block {...rest} /> : <></>;
};

export default DynamicContent;
